var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// ----------------------------------------------------------------------
export default function Table(theme) {
    return {
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    position: 'relative',
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: theme.palette.action.selected,
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                        },
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: 'none',
                },
                head: {
                    color: theme.palette.text.secondary,
                    backgroundColor: theme.palette.background.neutral,
                },
                stickyHeader: {
                    backgroundColor: theme.palette.background.paper,
                    backgroundImage: "linear-gradient(to bottom, ".concat(theme.palette.background.neutral, " 0%, ").concat(theme.palette.background.neutral, " 100%)"),
                },
                paddingCheckbox: {
                    paddingLeft: theme.spacing(1),
                },
            },
        },
        MuiTablePagination: {
            defaultProps: {
                backIconButtonProps: {
                    size: 'small',
                },
                nextIconButtonProps: {
                    size: 'small',
                },
                SelectProps: {
                    MenuProps: {
                        MenuListProps: {
                            sx: {
                                '& .MuiMenuItem-root': __assign({}, theme.typography.body2),
                            },
                        },
                    },
                },
            },
            styleOverrides: {
                root: {
                    borderTop: "solid 1px ".concat(theme.palette.divider),
                },
                toolbar: {
                    height: 64,
                },
                actions: {
                    marginRight: theme.spacing(1),
                },
                select: {
                    '&:focus': {
                        borderRadius: theme.shape.borderRadius,
                    },
                },
            },
        },
    };
}
